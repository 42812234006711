<style lang="scss" scoped>
.container {
    position: relative;
    height: 500px;
    overflow: hidden;

    ul {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 999;
        background-color: #fff;
        overflow: scroll;
        cursor: pointer;

        li {
            display: flex;
            justify-content: space-between;
            padding: 8px;
            border: 1px solid #fff;;

            &.selected {
                border-color: #FE989E;;
                background-color: #FFEAEA;
                border-radius: 4px;
            }

            p {
                min-width: 80px;
                text-align: center;

                &:first-child {
                    min-width: 50px;
                }
            }
        }
    }
}

.amap {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
}
</style>
<template>
    <div class="container">
        <ul>
            <li v-for="(vm,index) in trackList"
                :key="vm.id"
                :class="current.id===vm.id?'selected':''"
                @click="current=current.id!==vm.id?vm:{}">
                <p v-if="vm.locationTime">{{ vm.locationTime | dateCus('hh:mm') }}</p>
                <p v-else>{{ index }}</p>
                <p>{{ vm.longitude }}</p>
                <p>{{ vm.latitude }}</p>
            </li>
        </ul>
        <div class="amap" :id="id"></div>
    </div>
</template>

<script type="text/ecmascript-6">
export default {
    //定义模版数据
    data() {
        return {
            trackMap: null,
            current: {}
        }
    },
    props: {
        id: {
            type: String,
            default: 'tripMap'
        },
        trackInfo: {
            type: Array,
            default: function () {
                return [];
            }
        }
    },
    //计算属性
    computed: {
        trackList() {
            return this.trackInfo
        }
    },
    //主件被加载完成
    async mounted() {
        const url = '//webapi.amap.com/maps?v=1.4.13&key=0bb2ba3ff63722595ab21b36357841cc&callback=onMapLoad1';
        const mapUiUrl = '//webapi.amap.com/ui/1.0/main.js?v=1.0.11';
        //加载地图
        this.$nextTick(async function () {
            await this.loadScrip(url, true);
            await this.loadScrip(mapUiUrl);
            let log = 121.450923;
            let lat = 31.225807;
            if (this.trackList && this.trackList.length > 0) {
                log = this.trackList[0].longitude;
                lat = this.trackList[0].latitude;
            }
            if (window.AMap)
                this.trackMap = new window.AMap.Map(this.id, {
                    center: [log, lat],
                    zoom: 11
                });
            if (this.trackList && this.trackList.length > 0)
                this.driverTrack();
        })
    },
    //定义事件方法
    methods: {
        //加载地图
        loadScrip(url, load) {
            return new Promise(function (resolve) {
                window.onMapLoad1 = function () {
                    resolve(true);
                }
                const script = document.createElement('script');
                script.charset = 'utf-8';
                script.src = url;
                script.onload = function () {
                    if (!load)
                        resolve(true);
                }
                document.head.appendChild(script);
            })

        },
        driverTrack() {
            let lineList = [];
            this.trackList.forEach(item => {
                let line = [item.longitude, item.latitude];
                lineList.push(line);
            })
            new window.AMap.Marker({
                map: this.trackMap,
                position: lineList[0],
                icon: "//webapi.amap.com/images/car.png",
                offset: new window.AMap.Pixel(-26, -13),
                autoRotation: true,
                angle: -90,
            })
            new window.AMap.Polyline({
                map: this.trackMap,
                path: lineList,
                showDir: true,
                strokeColor: "#28F",  //线颜色
                strokeWeight: 6      //线宽
            });
        },
        mapDestroy() {
            this.trackMap.destroy();
        },
        createMarker(longitude, latitude) {
            if (this.marker)
                this.trackMap.remove(this.marker);
            if (!longitude || !latitude)
                return;
            if (!this.icon)
                this.icon = new window.AMap.Icon({
                    size: new window.AMap.Size(32, 35),    // 图标尺寸
                    image: '//cdn.900u.xyz/map/select_ico.png?imageslim',  // Icon的图像
                    imageSize: new window.AMap.Size(32, 35)   // 根据所设置的大小拉伸或压缩图片
                });
            this.marker = new window.AMap.Marker({
                position: new window.AMap.LngLat(longitude, latitude),
                offset: new window.AMap.Pixel(-16, -35),
                icon: this.icon, // 添加 Icon 图标 URL
                title: '选中坐标'
            });
            this.trackMap.add(this.marker);
        }
    },
    watch: {
        current({longitude, latitude}) {
            this.createMarker(longitude, latitude)
        }
    }
}
</script>
