<template>
    <el-button size="small"
               type="text"
               class="btn-border-e6a23c"
               :loading="loading"
               @click="handleClick"
               v-if="showButton">
        设置VIP订单
    </el-button>
</template>

<script type="text/ecmascript-6">
import orderUrls from '@/www/urls/orderUrls'

export default {
    //定义模版数据
    data() {
        return {
            loading: false
        }
    },
    props: ["info"],
    //计算属性
    computed: {
        showButton() {
            const info = this.info;
            return this.$store.state.loginUserInfo.userType === '0' && info && [12, 18, 23, 24].includes(info.sourceType) && info.thirdOrderInfoDto && info.thirdOrderInfoDto.isVip === 0
        }
    },
    //定义事件方法
    methods: {
        async handleClick() {
            this.loading = true;
            try {
                await this.$confirm('确认设置为VIP订单?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                const ret = await orderUrls.addOrderVipApi({orderId: this.info.orderId});
                if (ret.success && ret.data) {
                    this.$emit("success", {
                        ...this.info,
                        thirdOrderInfoDto: {
                            ...this.info.thirdOrderInfoDto,
                            isVip: 1
                        }
                    });
                    this.$message.success("设置成功！")
                }
            } catch (e) {
                console.log(e);
            }
            this.loading = false;
        }
    }
}
</script>
