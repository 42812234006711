/**
* 查看司机行程录音
**/
<style lang="scss" scoped>
    .container {
        display: inline-block;
        dl {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            justify-content: space-between;
        }
        .list {
            height: 400px;
            overflow: scroll;
        }
    }
</style>
<template>
    <div class="container">
        <el-button size="small" type="text" @click="handleOpen">
            录音记录
        </el-button>
        <el-dialog title="录音记录" :visible.sync="dialogVisible" width="50%" @close="pause">
            <div class="list" v-if="list.length>0" v-loading="loading">
                <dl v-for="(vm,index) in list" :key="index">
                    <dt>
                        {{vm.driverUploadTime | dateCus}}
                    </dt>
                    <dd>
                        <audio :ref="'audio'+index"
                               @play="handlePlay(index)"
                               :src="vm.recordUrl" controls>
                        </audio>
                    </dd>
                </dl>
            </div>
            <p v-else>
                没有记录
            </p>
            <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="getData" :loading="loading">刷 新</el-button>
    <el-button type="danger" @click="dialogVisible = false">关 闭</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
    import {getDriverRecord} from '@/www/urls/orderUrls'

    export default {
        //定义模版数据
        data() {
            return {
                dialogVisible: false,
                list: [],
                loading: false,
                playIndex: null
            }
        },
        props: {
            info: {
                type: Object,
                default: () => ({})
            }
        },
        computed: {
            show() {
                const {orderStatus} = this.info;
                return orderStatus > 41 && orderStatus < 70
            }
        },
        //定义事件方法
        methods: {
            async getData() {
                this.loading = true;
                this.list = [];
                const {driverGuiderNo, orderId} = this.info;
                const ret = await getDriverRecord({
                    driverId: driverGuiderNo,
                    orderId
                });
                if (ret.success && ret.data) {
                    let obj = {};
                    ret.data.forEach((item) => {
                        obj[item.recordUrl] = item;
                    })
                    this.list = Object.values(obj);

                }
                this.loading = false;
            },
            handleOpen() {
                this.dialogVisible = true;
                this.getData();
            },
            handlePlay(index) {
                if (this.playIndex != null && index != this.playIndex) {
                    this.pause();
                }
                this.playIndex = index;
            },
            pause() {
                const el = this.$refs['audio' + this.playIndex];
                el[0] && el[0].pause();
            }
        }

    }
</script>
