<template>
    <el-upload :limit="1"
               ref="upload"
               style="display: inline-block"
               action="https://900etrip.com"
               :loading="loading"
               :auto-upload="false"
               accept=".xlsx"
               :show-file-list="false"
               :on-change="handleChange">
        <slot></slot>
    </el-upload>
</template>

<script type="text/ecmascript-6">
import orderUrls from '@/www/urls/orderUrls'
import {formatExcelDate, uploadExcel} from "@/common/js/Utils";
import dayjs from "dayjs";

const keys = {
    "下单机构": "orderOrginfoName",
    "出行人姓名": "tripUserName",
    "出行人手机号": "tripUserMobile",
    "出行日期": "reserveTime",
    "导游": "guiderName",
    "订单金额": "orderAmount",
    "订单号": "thirdOrderId",
    "结算金额": "settleAmount",
    "资源": "resourceName",
    "应收金额": "receivableAmount"
}

export default {
    name: 'BtnTripOrderImport',
    //定义模版数据
    data() {
        return {
            loading: false
        }
    },
    props: {
        type: {
            type: String,
            default: 'userCar'
        }
    },
    //计算属性
    computed: {
        showButton() {
            return this.type === "trip"
        }
    },
    //定义事件方法
    methods: {
        async handleChange(file) {
            try {
                this.loading = true
                const data = await uploadExcel(file.raw);
                const form = [];
                let msg
                for (let item of data) {
                    const it = {}
                    for (let key in item) {
                        if (keys[key]) {
                            it[keys[key]] = typeof item[key] === 'string' ? item[key].replace(/\s/g, '') : item[key]
                        }
                        if ('出行日期' === key) {
                            let value = item['出行日期']
                            if (!dayjs(value, 'YYYY-MM-DD', true).isValid())
                                msg = `出行日期【格式】应为${dayjs().format('YYYY-MM-DD')}，请修改后上传`
                            if (typeof value === 'number') {
                                msg = undefined;
                                value = formatExcelDate(value, '/');
                            }
                            it["reserveTime"] = dayjs(value).valueOf();
                        }
                    }
                    form.push(it)
                }
                if (msg)
                    return this.$alert(msg, '提示', {
                        type: 'error'
                    })
                const ret = await orderUrls.tripOrderImportApi(form)
                if (!ret.success)
                    throw ret.errors[0].message
                this.$message.success("导入成功~")
                this.$emit('success')
            } catch (err) {
                this.$message.error(err.toString())
            } finally {
                this.loading = false
                this.$refs['upload'].clearFiles();
            }
        }
    }
}
</script>
