import { render, staticRenderFns } from "./recording-itinerary.vue?vue&type=template&id=c4b83dd6&scoped=true&"
import script from "./recording-itinerary.vue?vue&type=script&lang=js&"
export * from "./recording-itinerary.vue?vue&type=script&lang=js&"
import style0 from "./recording-itinerary.vue?vue&type=style&index=0&id=c4b83dd6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4b83dd6",
  null
  
)

export default component.exports