<style lang="scss" scoped>
.container {
    display: inline;
}
</style>
<template>

    <div class="container">
        <el-button size="small" type="text" @click="handleShow">途经点</el-button>
        <el-dialog
            title="途经点"
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose">
            <el-timeline>
                <el-timeline-item
                    v-for="(vm, index) in getList"
                    :key="index"
                    :timestamp="vm.viaPointStatus | formatDesc(viaPointStatus)">
                    {{ vm.name }}
                </el-timeline-item>
            </el-timeline>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
export default {
    //定义模版数据
    data() {
        return {
            viaPointStatus: [
                {
                    label: '未到达',
                    value: 0
                },
                {
                    label: '已到达',
                    value: 1
                }
            ],
            dialogVisible: false
        }
    },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        orderInfo: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        getList() {
            let list = [...this.list]
            if (list.length && this.orderInfo.departure.location === `${this.list[0].lon},${this.list[0].lat}`)
                list.shift();
            return list;
        }
    },
    //定义事件方法
    methods: {
        handleShow() {
            console.log(this.orderInfo)
            this.dialogVisible = true;
        },
        handleClose() {
            this.dialogVisible = false;
        }
    },
}
</script>
