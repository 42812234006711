<!--
import SeatModel from "@/components/seatModel/index";
<seat-model v-model="searchForm.saddle"></seat-model>
-->
<template>
    <el-select :value="value"
               @change="selectCar"
               :multiple="multiple"
               :disabled="disabled"
               :clearable="clearable"
               :placeholder="placeholder"
               :size="size"
               @clear="handleClear"
               style="width: 100%">
        <el-option label="全车型" :value="0" v-if="isAll"></el-option>
        <el-option v-for="car in list"
                   :key="car.value"
                   :label="car.name"
                   :value="car.value">
        </el-option>
    </el-select>
</template>

<script type="text/ecmascript-6">
import {seatModelList} from "@/data/index"

export default {
    //定义模版数据
    data() {
        return {
            seatModelList
        }
    },
    props: {
        value: {
            type: [Number, Array, null, String],
            default: 1
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isAll: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: "请选择"
        },
        priceWay: {
            type: String,
            default: "0"
        },
        multiple: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            value: null
        }
    },
    //计算属性
    computed: {
        list() {
            let list = JSON.parse(JSON.stringify(this.$store.state.seatModelList));
            list.forEach((item, index) => {
                if (this.priceWay === "0" && item.value == 14) {
                    return list.splice(index, 1);
                }
            })
            return list;
        }
    },
    //定义事件方法
    methods: {
        selectCar(val) {
            this.$emit('input', val);
            this.$emit('selectSeat', val)
        },
        handleClear() {
            this.$emit('input', null);
            this.$emit('selectSeat', null)
        },
        onStorageChange(event) {
            console.log(event);
        },
    }
}
</script>
