<template>
    <el-popover
        placement="top"
        width="300"
        v-model="visible">
        <h2 style="margin-bottom: 20px">变更运营商</h2>
        <el-form :model="form" :rules="rules" ref="form" inline>
            <el-form-item label="所属运营商" prop="operatorId">
                <OperatorSelect v-if="visible" v-model="form.operatorId" @change="handleChange"/>
            </el-form-item>
        </el-form>
        <div style="text-align: right; margin: 0">
            <el-button size="small" type="text" @click="visible = false" :loading="loading">取消</el-button>
            <el-button type="primary" size="small" @click="handleConfirm" :loading="loading">确定</el-button>
        </div>
        <el-button slot="reference" class="btn-border-e6a23c"
                   size="small" type="text">
            变更运营商
        </el-button>
    </el-popover>
</template>

<script>
import OperatorSelect from "@/www/components/operator-select";
import {reallocateOperatorApi} from "@/www/urls/orderUrls";

export default {
    name: "change-operator",
    data() {
        return {
            visible: false,
            loading: false,
            form: {
                operatorId: null,
                operatorName: ""
            },
            rules: {
                operatorId: [{required: true, message: '请选择运营商'}]
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo
        }
    },
    mounted() {
        this.form.operatorId = this.info.productOperatorId
    },
    components: {
        OperatorSelect
    },
    props: {
        info: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        async handleConfirm() {
            try {
                this.loading = true;
                await this.$refs.form.validate();
                await this.$confirm('确定变更订单运营商?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                const ret = await reallocateOperatorApi({
                    operatorId: this.form.operatorId,
                    orderId: this.info.orderId,
                });
                if (ret.success) {
                    this.$message.success("运营商信息变更成功！")
                    this.$emit('success', {
                        ...this.info,
                        productOperatorId: this.form.operatorId,
                        operatorName: this.form.operatorName
                    })
                } else
                    this.$message.error(ret.message)
            } finally {
                this.loading = false;
            }
        },
        handleChange({operatorName}) {
            this.form.operatorName = operatorName;
        }
    },
    watch: {
        info(n) {
            this.form.operatorId = n.productOperatorId
        }
    }
}
</script>

<style scoped>

</style>
