<template>
    <el-form size="small" label-position="top" ref="formRef">
        <el-form-item label="事件类型" required>
            <ApiSelect filterable placeholder="请选择事件类型" style="width: 100%"
                       :options="workOrderEventTypeOptions" v-model="form.eventTypeId"></ApiSelect>
        </el-form-item>
        <el-form-item label="内容" required>
            <el-input type="textarea" placeholder="请输入内容" :rows="5" v-model="form.content"></el-input>
        </el-form-item>
        <el-form-item>
            <fileUpload title="上传附件" :max-size="20*1024" buttonType="default" button-size="mini">
                <p style="font-size: 12px;color: #999">单个文件不得超过20M，可上传多个</p>
            </fileUpload>
        </el-form-item>
        <el-form-item>
            <el-button size="medium" style="width: 100%" type="primary" :loading="loading" @click="handleSubmit">确认
            </el-button>
        </el-form-item>
    </el-form>
</template>
<script>
import {workOrderEventTypeOptions} from '@/data/workOrder'
import fileUpload from "@/components/fileUpload/index.vue";
import {createOrderEventApi} from "@/www/urls/workOrder";

export default {
    name: "CreateWorkOrder",
    components: {fileUpload},
    data() {
        return {
            workOrderEventTypeOptions,
            loading: false,
            form: {
                eventTypeId: undefined,
                content: undefined
            }
        }
    },
    props: {
        order: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        async handleSubmit() {
            try {
                this.loading = true;
                await this.$refs['formRef'].validate();
                const ret = await createOrderEventApi({
                    ...this.form,
                    orderNo: this.order.orderNo,
                    orderId: this.order.orderId,
                    eventDetail: JSON.stringify({
                        eventTypeId: this.form.eventTypeId,
                        eventTypeName: workOrderEventTypeOptions.find(({value}) => value === this.form.eventTypeId).label
                    })
                })
                if (ret.success) {
                    this.$message.success('事件发布成功！');
                    this.$emit('success');
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style scoped lang="scss">

</style>
