<style lang="scss" scoped>
.file-select-btn {
    width: 80px;

}
</style>
<template>
    <div>
        <el-upload class="avatar-uploader"
                   action="https://up.qiniu.com"
                   :file-list="fileList"
                   :accept="accept"
                   :before-upload="handleChange"
                   :on-success="handleSuccess"
                   :on-error="handleError"
                   :data="uploadFile"
                   :disabled="disabled"
                   :show-file-list="showFileList"
                   :on-remove="handleRemove">
            <el-button v-if="!customStyle"
                       slot="trigger"
                       :size="buttonSize"
                       :type="buttonType"
                       :disabled="disabled"
                       :loading="loading"
                       class="file-select-btn">
                {{ title }}
            </el-button>
            <slot></slot>
            <el-button size="small" type="primary" class="upload-file-btn" v-if="fileUrl && isFileLook"
                       @click="uploadFileLook">查看
            </el-button>
        </el-upload>
    </div>
</template>

<script type="text/ecmascript-6">
import conf from '@/config/index'

export default {
    //定义模版数据
    data() {
        return {
            loading: false,
            uploadFile: {},
            fileUrl: '',
            fileList: []
        }
    },
    props: {
        id: {
            type: String,//元素的唯一性
            default: ''
        },
        title: {
            type: String,
            default: '上传'
        },
        showFileList: {
            type: Boolean,
            default: true
        },
        accept: {
            type: String,
            default: 'image/*'
        },
        maxSize: {
            type: Number,
            default: 0
        },
        isFileLook: {
            type: Boolean,
            default: false
        },
        customStyle: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        buttonType: {
            type: String,
            default: 'primary'
        },
        buttonSize: {
            type: String,
            default: 'small'
        }
    },
    //定义事件方法
    methods: {
        async handleChange(file) {
            if (this.maxSize > 0) {
                if (file.size > 1024 * 1024 * this.maxSize) {
                    this.$message('您上传的文件太大了，请上传不超过5M的文件');
                    return false;
                }
            }
            let upImgKey = '3.0/' + (conf.Debug ? 'develop' : 'release')
                + '/user/upImage/' + (+new Date()) + '/';
            let extend = ".jpg";
            if (file.name) {
                extend = file.name.split('.')[1];
            }
            let imgName = (+new Date()) + '.' + extend;
            let res = await this.http('/galaxyQiniu/getToken', {'upKey': upImgKey + imgName}, 'POST');
            if (res.success) {
                this.uploadFile = {key: (upImgKey + imgName), token: res.data.resultUrl};
            }
            this.loading = true;
            this.$emit('onStart')
        },
        handleError() {
            this.loading = false;
        },
        handleSuccess(res, file) {
            this.fileUrl = 'https://cdn.900etrip.com/' + res.key;
            this.fileList = [{name: file.name, fileUrl: this.fileUrl}];
            this.$emit('update:fileUploadCompleted', this.fileUrl);
            this.$emit('input', this.fileUrl);
            this.$emit('onCompleted', {
                id: this.id,
                url: this.fileUrl,
                file
            });
            this.loading = false;
        },
        handleRemove(file) {
            this.fileUrl = '';
            this.$emit('update:fileUploadCompleted', '');
            this.$emit('input', '')
            this.$emit('onCompleted', {
                id: this.id,
                url: ''
            });
            this.$emit('onRemove', file)
        },
        uploadFileLook() {
            this.$emit('update:uploadFileLook');
        }
    },
    //监听模版变量
    watch: {}

}
</script>
