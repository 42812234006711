<template>
    <el-dialog :visible.sync="visible" title="事件详情">
        <el-descriptions :column="2" v-if="record.id">
            <el-descriptions-item label="事件类型" :span="2">
                <el-tag type="primary" effect="plain" size="mini">
                    {{ record.eventDetail.eventTypeName }}
                </el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="发送时间">{{ record.createTime | dateCus }}</el-descriptions-item>
            <el-descriptions-item label="到期时间" v-if="record.deadLine">{{ record.deadLine }}</el-descriptions-item>
            <el-descriptions-item label="完成时间" :span="2">
                <span v-if="record.status===2">{{ record.completeTime | dateCus }}</span>
            </el-descriptions-item>
            <el-descriptions-item label="发送人">{{ record.createUserName }}</el-descriptions-item>
            <el-descriptions-item label="处理人">暂无</el-descriptions-item>
            <el-descriptions-item label="内容描述">
                <div v-ellipsis="{rows:4,lineHeight:21}">
                    <div v-html="record.content"></div>
                </div>
            </el-descriptions-item>
        </el-descriptions>
        <div v-if="record.createType===1&&record.status===0">
            <el-divider></el-divider>
            <el-form :model="form" ref="formRef" v-loading="loading">
                <el-form-item>
                    <h2>回复事件</h2>
                </el-form-item>
                <el-form-item label="回执结果（核实类事件）">
                    <ApiRadio :options="types" v-model="form.singleCallBack"></ApiRadio>
                </el-form-item>
                <el-form-item label="回复消息" prop="content" :rules="[{required:true,message:'请输入回复消息'}]">
                    <el-input v-model="form.content" type="textarea"></el-input>
                </el-form-item>
                <el-form-item>
                    <fileUploadMultiple accept="*"
                                        title="上传附件"
                                        :max-size="10240"
                                        :customStyle="true"
                                        v-model="form.enclosure"
                                        @onChange="handleFilesChange">
                        <el-button size="mini">上传附件</el-button>
                    </fileUploadMultiple>
                </el-form-item>
            </el-form>
        </div>
        <div slot="footer" v-if="record.createType===1&&record.status===0">
            <el-button :loading="loading" @click="visible=false">取消</el-button>
            <el-button type="primary" :loading="loading" @click="handleSubmit">提交</el-button>
        </div>
    </el-dialog>
</template>
<script>
import fileUploadMultiple from "@/components/fileUploadMultiple/index.vue";
import {replyOrderEventApi} from "@/www/urls/workOrder";

export default {
    name: "WorkOrderDetailDialog",
    data() {
        return {
            types: [{label: '同意', value: true}, {label: '不同意', value: false}],
            loading: false,
            visible: false,
            record: {},
            form: {
                id: undefined,
                content: undefined,
                singleCallBack: undefined,
                enclosure: []
            }
        }
    },
    components: {
        fileUploadMultiple
    },
    methods: {
        open(record) {
            this.record = record;
            this.form.id = record.id;
            this.form.content = undefined;
            this.form.singleCallBack = undefined;
            this.form.enclosure = [];
            this.files = []
            this.visible = true;
        },
        async handleSubmit() {
            try {
                this.loading = true;
                await this.$refs.formRef.validate()
                const ret = await replyOrderEventApi({
                    ...this.form,
                    singleCallBack: undefined,
                    eventDetail: JSON.stringify({
                        ...this.record.eventDetail,
                        singleCallBack: this.form.singleCallBack ? this.types.find(({label}) => label === this.form.singleCallBack).value : undefined
                    }),
                    enclosure: this.files && this.files.length ? JSON.stringify(
                        this.files.map((item) => ({
                            fileType: item.name.split('.')[1],
                            fileName: item.name,
                            fileUrl: item.fileUrl,
                        }))) : undefined
                });
                if (ret.success) {
                    this.$message.success('回复消息提交成功！');
                    this.visible = false;
                    this.$emit('onChange', {
                        id: this.form.id,
                        status: 2
                    })
                } else {
                    this.$message.error(ret.errors[0].message)
                }
            } finally {
                this.loading = false;
            }
        },
        handleFilesChange(files) {
            this.files = files
        },
    }
}
</script>

<style scoped lang="scss">

</style>
