<style lang="scss" scoped>
.container {
    position: relative;

    .amap {
        width: 100%;
        height: 100%;
        min-height: 500px;
        overflow: hidden;
        margin: 0;
    }

    .tip {
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(255, 255, 255, .8);
        padding: 8px 16px;
        color: #333;
        line-height: 2em;
    }
}

</style>
<template>
    <div class="container">
        <div class="amap" :id="id"></div>
        <div class="tip">
            <p>预计总行驶里程：{{ totalDuration }}公里</p>
            <p>预计总行驶时间：{{ totalTime }}</p>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">

const images = {
    start: '//cdn.900u.xyz/images/map/start.png',
    end: '//cdn.900u.xyz/images/map/end.png',
    t1: '//cdn.900u.xyz/images/map/t1.png',
    t2: '//cdn.900u.xyz/images/map/t2.png',
    t3: '//cdn.900u.xyz/images/map/t3.png',
    t4: '//cdn.900u.xyz/images/map/t4.png',
    t5: '//cdn.900u.xyz/images/map/t5.png',
    car: {
        uri: "//cdn.900u.xyz/images/map/car.png",
        size: [90, 39]
    }
}
export default {
    //定义模版数据
    data() {
        return {
            map: null,
            result: {}
        }
    },
    props: {
        id: {
            type: String,
            default: 'tripMap'
        },
        info: {
            type: Object,
            default: function () {
                return null;
            }
        }
    },
    computed: {
        totalTime() {
            const {routes} = this.result;
            if (routes && routes.length > 0) {
                const time = routes[0].time;
                return `${Math.floor(time / (60 * 60))}小时${Math.ceil((time % (60 * 60)) / 60)}分`
            }
            return 0;
        },
        totalDuration() {
            const {routes} = this.result;
            return (routes && routes.length > 0 ? routes[0].distance / 1000 : 0).toFixed('2')
        }
    },
    //主件被加载完成
    async mounted() {
        //加载地图
        const url = '//webapi.amap.com/maps?v=2.0&key=0bb2ba3ff63722595ab21b36357841cc&callback=onMapLoad&plugin=AMap.Driving';
        // const mapUiUrl = '//webapi.amap.com/ui/1.1/main.js';
        await this.loadScrip(url, true);
        // await this.loadScrip(mapUiUrl);
        this.map = new window.AMap.Map(this.id, {
            center: [117.000923, 36.675807],
            zoom: 11,
            resizeEnable: true
        });
        if (this.info) this.getDriverRoute();
    },
    //定义事件方法
    methods: {
        //加载地图
        loadScrip(url, load) {
            return new Promise(function (resolve) {
                window.onMapLoad = function () {
                    resolve(true);
                }
                const script = document.createElement('script');
                script.charset = 'utf-8';
                script.src = url;
                script.onload = function () {
                    if (!load)
                        resolve(true);
                }
                document.head.appendChild(script);
            })
        },
        addMark(position, iconPath, title) {
            const w = 64, h = 82;
            const icon = new window.AMap.Icon({
                size: new window.AMap.Size(w, h),    // 图标尺寸
                image: iconPath,  // Icon的图像
                imageSize: new window.AMap.Size(w / 2, h / 2)   // 根据所设置的大小拉伸或压缩图片
            });
            const marker = new window.AMap.Marker({
                map: this.map,
                position,
                icon,
                offset: new window.AMap.Pixel(-w / 4, -h / 4),
            })
            marker.setTitle(title);
            marker.setLabel({
                direction: 'top',
                content: title
            });

        },
        driverPolicy(list) {
            if (!window.AMap)
                return;
            this.map.clearMap();
            const path = list.map((item) => new window.AMap.LngLat(item[0], item[1]));
            // 创建折线实例
            const polyline = new window.AMap.Polyline({
                path: path,
                showDir: true,
                strokeOpacity: 1,
                strokeColor: "#319bf7",  //线颜色
                strokeWeight: 6,     //线宽
                lineJoin: 'round' // 折线拐点连接处样式
            });
            // 将折线添加至地图实例
            this.map.add(polyline);
            if (path.length > 0)
                this.map.setCenter(path[0])
            this.map.setFitView();
        },
        async getDriverRoute() {
            this.map.plugin('AMap.Driving', async () => {
                let {
                    departure,
                    destination,
                    orderCustomizeInfoDto,
                    expectedDistanceLength,
                    orderViaPointDtos = [],
                    isHighSpeedPriority,
                    highSpeedViaPoint
                } = this.info;

                let driving = new window.AMap.Driving({
                    map: this.map,
                    policy: isHighSpeedPriority === 1 ? 19 : window.AMap.DrivingPolicy.REAL_TRAFFIC,
                    autoFitView: true,
                    showDir: true,
                    strokeOpacity: 1,
                    strokeWeight: 6,     //线宽
                    lineJoin: 'round' // 折线拐点连接处样式
                })

                if ((departure.point && destination.point) || (departure.location && destination.location && (!orderCustomizeInfoDto || !orderCustomizeInfoDto.itineraryInfo))) {
                    let startLngLat = departure.location.split(','),
                        endLngLat = destination.location.split(',');
                    orderViaPointDtos = orderViaPointDtos.filter(({
                                                                      lat,
                                                                      lon
                                                                  }) => `${lon},${lat}` !== departure.location)
                    if (departure.point && destination.point) {
                        startLngLat[0] = Number(departure.point.lng);
                        startLngLat[1] = Number(departure.point.lat);
                        endLngLat[0] = Number(destination.point.lng);
                        endLngLat[1] = Number(destination.point.lat);
                    }

                    //如果有预计公里数 就找一条最接近的线路
                    if (isHighSpeedPriority !== 1 && expectedDistanceLength) {
                        driving.setPolicy(11)
                        driving.search(startLngLat, endLngLat, {
                            waypoints: orderViaPointDtos.map(({lat, lon}) => `${lon},${lat}`).join(';')
                        }, (status, result) => {
                            console.log(status, result);
                            if (status === 'complete') {
                                let rRoute = result.routes[0];
                                if (result.routes.length > 1) {
                                    const distance = expectedDistanceLength * 1000;
                                    let diff = Infinity;
                                    result.routes.forEach((route) => {
                                        if (Math.abs(route.distance - distance) < diff) {
                                            diff = Math.abs(route.distance - distance);
                                            rRoute = route;
                                        }
                                    });
                                    let list = [];
                                    rRoute.steps.forEach((step) => {
                                        list = [...list, ...step.path.map((item) => [item.lng, item.lat])]
                                    })
                                    driving.clear()
                                    this.driverPolicy(list);
                                    this.addMark(list[0], images.start, departure.title);
                                    this.addMark(list[list.length - 1], images.end, destination.title);
                                    orderViaPointDtos.forEach((item, index) => {
                                        this.addMark([item.lon, item.lat], images[`t${index + 1}`], item.name);
                                    })
                                }
                                console.log(rRoute);
                                this.result = {
                                    routes: [rRoute],
                                };
                            }
                        })
                    } else {
                        let waypoints = orderViaPointDtos.map(({lat, lon}) => [lon, lat]);
                        if (isHighSpeedPriority === 1 && highSpeedViaPoint) {
                            try {
                                waypoints = [...waypoints, ...JSON.parse(highSpeedViaPoint).map(({
                                                                                                     longitude,
                                                                                                     latitude
                                                                                                 }) => [longitude, latitude])]
                            } catch (err) {
                                console.log(err)
                            }
                        }
                        driving.search(startLngLat, endLngLat, {
                            waypoints
                        }, (status, result) => {
                            // 未出错时，result即是对应的路线规划方案
                            this.result = result;
                            let list = [];
                            this.result.routes[0].steps.forEach((step) => {
                                list = [...list, ...step.path.map(({lng, lat}) => [lng, lat])]
                            })
                            this.driverPolicy(list);
                            this.addMark(list[0], images.start, departure.title);
                            this.addMark(list[list.length - 1], images.end, destination.title);
                            orderViaPointDtos.forEach((item, index) => {
                                this.addMark([item.lon, item.lat], images[`t${index + 1}`], item.name);
                            })
                            if (status === 'complete')
                                this.$emit('tripInfo', result)
                        })
                    }
                } else {
                    let points = [{keyword: departure.address, city: departure.city}]
                    const {itineraryInfo} = orderCustomizeInfoDto || {};
                    if (itineraryInfo) {
                        let itinerary = JSON.parse(itineraryInfo)[0];
                        if (itinerary.wayAddressList) {
                            for (let item of itinerary.wayAddressList) {
                                points.push({
                                    keyword: item.tripWayPlace.title,
                                    city: item.tripWayPlace.city
                                })
                            }
                        }
                    }
                    points.push({keyword: destination.address, city: destination.city});
                    driving.search(points, (status, result) => {
                        // 未出错时，result即是对应的路线规划方案
                        this.result = result;
                        let list = [];
                        this.result.routes[0].steps.forEach((step) => {
                            list = [...list, ...step.path.map(({lng, lat}) => [lng, lat])]
                        })
                        this.driverPolicy(list);
                        this.addMark(list[0], images.start, departure.title);
                        this.addMark(list[list.length - 1], images.end, destination.title);
                        orderViaPointDtos.forEach((item, index) => {
                            this.addMark([item.lon, item.lat], images[`t${index + 1}`], item.name);
                        })
                        if (status === 'complete')
                            this.$emit('tripInfo', result)
                    })
                }
            })
        },
        mapDestroy() {
            this.map.destroy();
        }
    }
}
</script>
