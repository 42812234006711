<template>
    <div>
        <div v-loading="loading">
            <el-table :data="result.data" size="mini">
                <el-table-column label="事件ID" prop="ctripEventId" width="90"></el-table-column>
                <el-table-column label="发送人" width="90">
                    <template slot-scope="scope">
                        {{ scope.row.createUserName }}
                    </template>
                </el-table-column>
                <!--                <el-table-column label="事件来源" width="90">-->
                <!--                    <template slot-scope="scope">-->
                <!--                        <el-tag effect="dark"-->
                <!--                                size="mini"-->
                <!--                                :type="scope.row.eventSource | formatDesc(workOrderTypeOptions,'value','type')">-->
                <!--                            {{ scope.row.eventSource | formatDesc(workOrderTypeOptions) }}-->
                <!--                        </el-tag>-->
                <!--                    </template>-->
                <!--                </el-table-column>-->
                <el-table-column label="事件内容">
                    <template slot-scope="scope">
                        <el-tag type="primary" size="mini" v-if="scope.row.eventSource === 1" effect="plain">
                            {{
                                scope.row.eventDetail.eventTypeName
                            }}
                        </el-tag>
                        <div v-if="scope.row.eventSource === 2">
<!--                            <el-tag size="mini"-->
<!--                                    effect="plain"-->
<!--                                    :type="scope.row.punishOrderStatus | formatDesc(punishOrderStatusOptions,'value','type')">-->
<!--                                单据状态： {{ scope.row.punishOrderStatus | formatDesc(punishOrderStatusOptions) }}-->
<!--                            </el-tag>-->
                            <el-tag size="mini"
                                    effect="plain"
                                    :type="scope.row.appealStatus | formatDesc(appealStatusOptions,'value','type')">
                                {{ scope.row.appealStatus | formatDesc(appealStatusOptions) }}
                            </el-tag>
                        </div>
                        <div v-html="scope.row.content"></div>
                        <div>附件：</div>
                    </template>
                </el-table-column>
                <el-table-column label="回复截止时间/申述截止时间" width="250">
                    <template slot-scope="scope">
                        <div v-if="scope.row.createType===1">
                            {{
                                scope.row.eventSource === 1 ? `请于${scope.row.deadLine}前回复` :
                                    `请于${scope.row.deadLine}前发起申诉`
                            }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100">
                    <template slot-scope="scope" v-if="scope.row.createType===1">
                        <el-button type="text" size="mini" v-if="scope.row.eventSource===1"
                                   @click="handleDetail(scope.row)">
                            {{ scope.row.status === 0 ? '回复' : '详情' }}
                        </el-button>
                        <el-button size="mini" type="text" v-if="scope.row.eventSource===2"
                                   @click="handleDetail(scope.row)">
                            {{
                                scope.row.appealable === '1' && scope.row.appealStatus === 'appealing' ? '进行申诉' : '详情'
                            }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div v-if="result.total>0" style="display: flex;justify-content: flex-end;margin-top: 10px">
            <el-pagination background layout="prev, pager, next"
                           :current-page="form.pageIndex"
                           :total="result.total"
                           @current-change="getList">
            </el-pagination>
        </div>
        <WorkOrderDetailDialog ref="workOrderDetailDialogRef" @onChange="handleOnChange"></WorkOrderDetailDialog>
    </div>
</template>

<script>
import {
    workOrderStatusOptions,
    workOrderTypeOptions,
    appealStatusOptions,
    punishOrderStatusOptions
} from '@/data/workOrder'
import dayjs from "dayjs";
import {getWorkOrderList} from "@/www/urls/workOrder";
import WorkOrderDetailDialog from "@/www/pages/workOrder/WorkOrderDetailDialog.vue";

const createForm = () => ({
    pageIndex: 1,
    pageSize: 5,
    orderNo: undefined,
    masterOrderId: undefined,
    eventSource: undefined,
    status: undefined,
})
export default {
    name: "WorkOrder",
    data() {
        return {
            workOrderStatusOptions,
            workOrderTypeOptions,
            appealStatusOptions,
            punishOrderStatusOptions,
            loading: true,
            form: createForm(),
            result: {
                data: [],
                total: 0
            }
        }
    },
    props: {
        order: {
            type: Object,
            default: () => ({})
        }
    },
    filters: {
        filterState(val, record) {
            if (record.isDelete === 1)
                return '已删除'
            if (val === 1)
                return '已取消'
            return !record.pushTime || (dayjs(record.pushTime).isBefore(dayjs())) ? '已发布' : '待发布'
        },
        filterStateType(val, record) {
            if (val === 1)
                return 'info'
            return !record.pushTime || (dayjs(record.pushTime).isBefore(dayjs())) ? 'success' : 'warning'
        }
    },
    components: {
        WorkOrderDetailDialog,
    },
    mounted() {
        this.getList();
    },
    methods: {
        refresh() {
            this.form = createForm();
            this.getList();
        },
        showEdit(record) {
            return record.status === 0 && dayjs(record.pushTime).isAfter(dayjs())
        },
        async getList(page = 1) {
            this.form.pageIndex = page;
            this.form.orderNo = this.order.orderNo;
            this.loading = true
            const ret = await getWorkOrderList(this.form)
            this.loading = false
            if (ret.success) {
                ret.data.data = ret.data.data.map((item) => ({
                    ...item,
                    eventDetail: item.eventDetail ? JSON.parse(item.eventDetail) : {},
                    punishDetail: item.punishDetail ? JSON.parse(item.punishDetail) : {},
                }))
                this.result = ret.data;
            } else this.$message.error(ret.errors[0].message)

        },
        handleDetail(record) {
            if (record.eventSource === 1)
                this.$refs.workOrderDetailDialogRef.open(record);
            else
                this.$router.push(`/workOrderDetail?id=${record.ctripEventId}`)
        },
        handleOnChange(record) {
            this.result.data = this.result.data.map((item) => ({
                ...item,
                ...(record.id === item.id ? record : {})
            }))
        }
    }
}
</script>

<style scoped lang="scss">
.list-item {
    border: 1px solid #f2f4f6;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    background-color: #fff;

    &:hover {
        box-shadow: 0 0 12px 4px rgba(16, 34, 71, .1);
        -webkit-box-shadow: 0 0 12px 4px rgba(16, 34, 71, .1);
        -webkit-transform: translateY(-2px);
        -ms-transform: translateY(-2px);
        transform: translateY(-2px);
        -webkit-transition: all .2s ease-out;
        transition: all .2s ease-out;
    }

    .list-item_hd {
        border-bottom: 1px solid #f2f4f6;
        display: flex;
        padding-bottom: 10px;
    }

    .list-item_bd {
        padding-top: 10px;
        display: flex;

        & > div:not(:first-child) {
            flex: 1;
        }

        & > div:first-child {
            width: 50%;
            padding-right: 50px;
        }
    }
}
</style>
