<style lang="scss" scoped>

</style>
<template>

    <el-select class="resource-supplier"
               filterable
               clearable
               style="width: 100%"
               :disabled="disabled"
               @change="handleChange"
               v-model="form.id"
               placeholder="运营商"
               @clear="clear"
    >
        <el-option
                v-for="item in list"
                :key="item.id"
                :label="item.operatorName"
                :value="item.id">
        </el-option>
    </el-select>

</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            return {
                form: {
                    id: null
                },
                list: []
            }
        },
        props: {
            disabled: {
                type: Boolean,
                value: false
            },
            value: {
                type: String,
                value: null
            }
        },
        //计算属性
        computed: {
            userInfo() {
                return this.$store.state.loginUserInfo;
            }
        },
        //主件被加载完成
        mounted() {
            this.getData();
        },
        //定义事件方法
        methods: {
            async getData() {
                let ret = await this.http('/galaxyOperator/operatorInfoList', {}, "POST");
                let list = [];
                if (ret.success && ret.data) {
                    list = ret.data;
                }
                this.list = list;
                return true;
            },
            handleChange(val) {
                this.$emit('input', val);
                this.$emit('change', this.list.find((item) => item.id === val));
            },
            clear(){
                this.$emit('clear')
            }
        },
        //监听模版变量
        watch: {
            value(n) {
                this.form.id = n
            }
        }

    }
</script>
