<style lang="scss" scoped>
    ul {
        background: #F0F8FF;
        padding: 16px;
    }

    li {
        margin-bottom: 10px;
    }

    .time {
        font-weight: 500;
    }

    .list-item {
        display: flex;
        margin-top: 10px;
        align-items: flex-start;
    }

    .label {
        width: 70px;
    }

    .content {
        flex: 1;
        line-height: 1.5em;
    }

    .address {
        font-size: 12px;
        color: #666;
    }
</style>
<template>
    <ul>
        <li v-for="(item,index) in getData" :key="index">
            <p class="time">第{{index + 1}}天({{item.date}})</p>
            <div class="list-item">
                <div class="label">出行时间:</div>
                <div class="content">
                    {{item.handTripTime}}
                </div>
            </div>
            <div class="list-item">
                <div class="label">上车地点:</div>
                <div class="content">
                    <p v-if="item.tripStartPlace.title">
                        {{`${item.tripStartPlace.city || ''} ${item.tripStartPlace.title || ''} `}}
                    </p>
                    <p class="address">{{`${item.tripStartPlace.address || ''}`}}</p>
                </div>
            </div>
            <div class="list-item" v-for="(vm,index) in item.wayAddressList" :key="index">
                <div class="label">途经点:</div>
                <div class="content">
                    <p v-if="vm.tripWayPlace.title">
                        {{`${vm.tripWayPlace.city || ''} ${vm.tripWayPlace.title || ''}`}}
                    </p>
                    <p class="address">{{`${vm.tripWayPlace.address || ''}`}}</p>
                </div>
            </div>
            <div class="list-item">
                <div class="label">下车地点:</div>
                <div class="content">
                    <p v-if="item.tripEndPlace.title">
                        {{`${item.tripEndPlace.city || ''} ${item.tripEndPlace.title || ''}`}}
                    </p>
                    <p class="address">{{`${item.tripEndPlace.address || ''}`}}</p>
                </div>
            </div>
        </li>
    </ul>

</template>

<script type="text/ecmascript-6">
    import Vue from 'vue';

    const dateCus = Vue.filter('dateCus')
    export default {
        //定义模版数据
        data() {
            return {}
        },
        props: {
            info: null
        },
        //计算属性
        computed: {
            getData() {
                const {orderCustomizeInfoDto={},reserveTime,departure,destination} = this.info;
                if (orderCustomizeInfoDto && orderCustomizeInfoDto.itineraryInfo&&orderCustomizeInfoDto.itineraryInfo!=='null')
                    return JSON.parse(orderCustomizeInfoDto.itineraryInfo)
                else
                    return [
                        {
                            date: dateCus(reserveTime, 'yyyy-MM-dd'),
                            handTripTime: dateCus(reserveTime, 'hh:mm'),
                            tripStartPlace: departure,
                            tripEndPlace: destination
                        }
                    ]
            }
        }

    }
</script>
